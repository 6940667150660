const path = require('path')

function titleCase(str) {
  return str.slice(0, 1).toUpperCase() + str.slice(1);
}

// export default  {
//   ResourceList: () => import('@/views/system/resource/ResourceList'),
//   RoleList: () => import('@/views/system/role/listPage/RoleList'),
//   UserList: () => import('@/views/system/user/listPage/UserList'),

//   ShopList: () => import('@/views/shop/listPage/ShopList'),
//   GoodsList: () => import('@/views/goods/listPage/GoodsList'),
// }


let files = require.context('./markMaps', false, /\.js$/)

const modules = []
files.keys().forEach(key => {
  const moduleName = path.basename(key, '.js')
  const ModuleName = titleCase(moduleName)
  modules[ModuleName + 'List'] = files(key).default[ModuleName + 'List']
})



// requireComponent.keys().filter((path) => {
//   let res = /^\.\/(.*?)\//.exec(path)
//   if (res && res[1] && !dirs.includes(res[1])) {
//     dirs.push(res[1])
//   }
// })


export default modules
